/* line 13, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.m-testimonial-results {
	position: relative;
	margin-top: 20px;
}

/* line 17, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.m-testimonial-results:empty {
	display: none;
}

/* line 23, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.pressandtestimonials--wrapper .m-testimonial-results .testimonial-results__card {
	padding: 10px 15px 10px 15px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .pressandtestimonials--wrapper .m-testimonial-results .testimonial-results__card {
	padding: 10px 15px 10px 15px;
}

/* line 27, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.pressandtestimonials--wrapper .m-testimonial-results .testimonial-results__titlebar {
	padding: 15px 15px 15px 15px;
	background-color: rgb(var(--color-global-title));
	color: rgb(var(--color-global-secondary));
	text-transform: uppercase;
	font-size: 1.2em;
	font-weight: 600;
	display: flex;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .pressandtestimonials--wrapper .m-testimonial-results .testimonial-results__titlebar {
	padding: 15px 15px 15px 15px;
}

/* line 43, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.pressandtestimonials--wrapper .m-testimonial-results .testimonial-results__titlebar .testimonial-results__viewall {
	flex: 1 1 auto;
	cursor: pointer;
	font-weight: normal;
	text-transform: capitalize;
}

/* line 55, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.pressandtestimonials--wrapper .m-testimonial-results .testimonial-results__card-date {
	font-weight: bold;
	margin-top: 10px;
}

/* line 60, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.pressandtestimonials--wrapper .m-testimonial-results .testimonial-results__card-name {
	color: rgb(var(--color-global-highlight));
	text-transform: uppercase;
}

/* line 65, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.pressandtestimonials--wrapper .m-testimonial-results .testimonial-results__icon,
.pressandtestimonials--wrapper .m-testimonial-results .c-paging-extended {
	display: none;
}

/* line 70, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.pressandtestimonials--wrapper .m-testimonial-results .testimonial-results__description {
	padding: 0;
	border-top: 0;
}

/* line 76, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.m-testimonial-results .testimonials-results__flex {
	display: flex;
	margin-top: 5px;
}

@media only screen and (max-width: 59.999em) {
	/* line 76, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
	.m-testimonial-results .testimonials-results__flex {
		flex-direction: column;
	}
}

/* line 84, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.m-testimonial-results .testimonials-results__flex .testimonials-card {
	flex: 1 1 30%;
}

/* line 88, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.m-testimonial-results .testimonials-results__flex .testimonials-content {
	flex: 1 1 100%;
	padding: 20px 15px;
	color: rgb(var(--color-global-secondary));
}

/* line 98, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.m-testimonial-results .testimonials-results__flex .testimonial-results__card {
	height: 100%;
	display: flex;
}

/* line 103, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.m-testimonial-results .testimonials-results__flex .testimonial-card__thumbnail {
	flex: 1 1 45%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	border-radius: 50%;
	max-width: 100px;
}

@media only screen and (max-width: 59.999em) {
	/* line 103, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
	.m-testimonial-results .testimonials-results__flex .testimonial-card__thumbnail {
		flex: 1 1 20%;
		max-height: 100px;
	}
}

/* line 117, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.m-testimonial-results .testimonials-results__flex .testimonial-card__thumbnail .o-smartimage .no-best-fit {
	border-radius: 50%;
	height: 100%;
}

/* line 123, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.m-testimonial-results .testimonials-results__flex .testimonial-card__details {
	padding-left: 20px;
	flex: 1 1 100%;
	justify-content: center;
	display: flex;
	flex-direction: column;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-testimonial-results .testimonials-results__flex .testimonial-card__details {
	padding-left: initial;
	padding-right: 20px;
}

/* line 146, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.m-testimonial-results .testimonials-results__flex .testimonial-results__card-name,
.m-testimonial-results .testimonials-results__flex .testimonial-results__card-position {
	margin-bottom: 10px;
}

/* line 154, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.testimonial-results__page-title {
	font-weight: 400;
	padding: 10px 0;
	text-align: center;
}

/* line 180, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.testimonial-results__wrapper {
	margin-top: 20px;
}

/* line 184, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.testimonial-results__description {
	padding: 30px 0 10px;
	border-top: 1px solid rgb(var(--color-global-border));
	font-family: var(--font-family-text);
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@media only screen and (max-width: 47.999em) {
	/* line 184, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
	.testimonial-results__description {
		font-size: 1em;
		line-height: 1.2992;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 184, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
	.testimonial-results__description {
		font-size: 1em;
		line-height: 1.4;
	}
}

@media only screen and (min-width: 80em) {
	/* line 184, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
	.testimonial-results__description {
		font-size: 1em;
		line-height: 1.4;
	}
}

/* line 205, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.testimonial-results__card {
	padding: 20px;
	background-color: rgb(var(--color-global-widget));
	box-sizing: border-box;
}

/* line 210, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.testimonial-results__card-name {
	font-family: var(--font-family-headings);
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	color: rgb(var(--color-global-negative));
}

@media only screen and (max-width: 47.999em) {
	/* line 210, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
	.testimonial-results__card-name {
		font-size: 1.1775em;
		line-height: 1.135;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 210, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
	.testimonial-results__card-name {
		font-size: 1.2855em;
		line-height: 1.5;
	}
}

@media only screen and (min-width: 80em) {
	/* line 210, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
	.testimonial-results__card-name {
		font-size: 1.5em;
		line-height: 1.5;
	}
}

/* line 229, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.testimonial-results__card-date {
	font-family: var(--font-family-text);
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	margin-top: 20px;
}

@media only screen and (max-width: 47.999em) {
	/* line 229, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
	.testimonial-results__card-date {
		font-size: 1em;
		line-height: 1.2992;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 229, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
	.testimonial-results__card-date {
		font-size: 1em;
		line-height: 1.4;
	}
}

@media only screen and (min-width: 80em) {
	/* line 229, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
	.testimonial-results__card-date {
		font-size: 1em;
		line-height: 1.4;
	}
}

/* line 235, scss/80-themes/Saunders/70-modules/testimonialsresultsmodule/module.scss */
.testimonial-results__icon {
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 0 20px;
	background-color: rgb(var(--color-global-body));
	color: rgb(var(--color-global-border));
	font-size: 2.5em;
}

/*# sourceMappingURL=../../../../../true */